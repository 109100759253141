import React from 'react';
import { Server } from 'lucide-react';
import { ApiEndpointTester } from './admin/components/ApiEndpointTester';

export function ApiTest() {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <h1 className="text-3xl font-bold flex items-center">
          <Server className="h-8 w-8 mr-2 text-blue-600" />
          API Endpoint Tester
        </h1>
        <p className="text-gray-600 mt-2">
          Use this tool to test individual API endpoints with custom parameters and request bodies.
        </p>
      </div>
      
      <ApiEndpointTester />
    </div>
  );
}