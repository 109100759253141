import React, { useState, useEffect } from 'react';
import { Key, CheckCircle, AlertCircle, Award, Loader2 } from 'lucide-react';
import { useEntitlementStore } from '../store/entitlement';
import { useAuthStore } from '../store/auth';
import { AdSense } from '../components/AdSense';

export function Entitlements() {
  const { userEntitlements, isLoading, error, fetchUserEntitlements, redeemCode } = useEntitlementStore();
  const { user } = useAuthStore();
  const [code, setCode] = useState('');
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [redeemError, setRedeemError] = useState<string | null>(null);
  const [redeemSuccess, setRedeemSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      fetchUserEntitlements();
    }
  }, [user, fetchUserEntitlements]);

  const handleRedeemCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setRedeemError(null);
    setRedeemSuccess(null);
    setRedeemLoading(true);

    try {
      if (!code.trim()) {
        throw new Error('Please enter a valid code');
      }

      await redeemCode(code.trim());
      setRedeemSuccess('Code redeemed successfully!');
      setCode('');
    } catch (err: any) {
      console.error('Error redeeming code:', err);
      setRedeemError(err.message || 'Failed to redeem code');
    } finally {
      setRedeemLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <AdSense />
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="flex items-center mb-6">
            <Award className="h-6 w-6 text-blue-600 mr-2" />
            <h2 className="text-2xl font-semibold">My Entitlements</h2>
          </div>

          {/* Redeem Code Form */}
          <div className="bg-blue-50 p-6 rounded-lg mb-8">
            <h3 className="text-lg font-medium text-blue-900 mb-4">Redeem a Code</h3>
            <form onSubmit={handleRedeemCode} className="flex flex-col sm:flex-row gap-4">
              <div className="flex-1">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Key className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value.toUpperCase())}
                    placeholder="Enter your code"
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    disabled={redeemLoading}
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={redeemLoading}
                className={`px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
                  redeemLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {redeemLoading ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin inline mr-2" />
                    Redeeming...
                  </>
                ) : (
                  'Redeem Code'
                )}
              </button>
            </form>

            {redeemError && (
              <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
                <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                <p>{redeemError}</p>
              </div>
            )}

            {redeemSuccess && (
              <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
                <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                <p>{redeemSuccess}</p>
              </div>
            )}
          </div>

          {/* Entitlements List */}
          <div>
            <h3 className="text-lg font-medium mb-4">Your Active Entitlements</h3>
            
            {isLoading ? (
              <div className="flex justify-center items-center py-8">
                <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
                <span className="ml-3">Loading entitlements...</span>
              </div>
            ) : error ? (
              <div className="p-4 bg-red-50 text-red-700 rounded-md flex items-center">
                <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                <p>{error}</p>
              </div>
            ) : userEntitlements.length === 0 ? (
              <div className="text-center py-8 bg-gray-50 rounded-lg">
                <Award className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-600">You don't have any entitlements yet.</p>
                <p className="text-gray-500 text-sm mt-2">
                  Redeem a code above to unlock special features.
                </p>
              </div>
            ) : (
              <div className="grid gap-4 md:grid-cols-2">
                {userEntitlements.map((userEntitlement) => (
                  <div key={userEntitlement.id} className="border rounded-lg p-4 hover:shadow-md transition-shadow">
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="font-medium text-lg">
                          {userEntitlement.entitlement?.name || 'Unknown Entitlement'}
                        </h4>
                        <div className="flex items-center mt-1 text-sm text-gray-500">
                          <Key className="h-4 w-4 mr-1" />
                          <span className="font-mono">{userEntitlement.entitlement?.code || 'N/A'}</span>
                        </div>
                        {userEntitlement.entitlement?.description && (
                          <p className="mt-2 text-gray-600 text-sm">
                            {userEntitlement.entitlement.description}
                          </p>
                        )}
                      </div>
                      {userEntitlement.entitlement?.is_ad_free && (
                        <span className="px-2 py-1 bg-green-100 text-green-800 text-xs font-medium rounded-full">
                          Ad-Free
                        </span>
                      )}
                    </div>
                    <div className="mt-4 pt-4 border-t border-gray-100 flex justify-between items-center text-sm text-gray-500">
                      <div>
                        Activated: {new Date(userEntitlement.created_at).toLocaleDateString()}
                      </div>
                      {userEntitlement.expires_at ? (
                        <div>
                          Expires: {new Date(userEntitlement.expires_at).toLocaleDateString()}
                        </div>
                      ) : (
                        <div className="text-green-600">Never expires</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}