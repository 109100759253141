import React, { useState } from 'react';
import { Webhook, CheckCircle, AlertCircle, Server } from 'lucide-react';
import { MobileApiService } from '../../../services/MobileApiService';
import { ApiEndpointTester } from './ApiEndpointTester';

export function ApiTester() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<{ success: boolean; message: string } | null>(null);
  const apiService = MobileApiService.getInstance();

  const handleTest = async () => {
    setLoading(true);
    setResult(null);
    
    try {
      const testResult = await apiService.testApiConnection();
      setResult(testResult);
    } catch (error: any) {
      setResult({
        success: false,
        message: `API test error: ${error.message}`
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      {/* Quick Connection Test */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="space-y-4">
          <div className="flex items-center gap-2">
            <Webhook className="h-5 w-5 text-blue-600" />
            <h2 className="text-xl font-semibold">Quick API Connection Test</h2>
          </div>
          
          <p className="text-gray-600">
            Test the mobile API connection to ensure it's properly configured and accessible.
            This will test the API running on the examengines.com domain.
          </p>
          
          <button
            onClick={handleTest}
            disabled={loading}
            className={`flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
                Testing...
              </>
            ) : (
              'Test API Connection'
            )}
          </button>

          {result && (
            <div className={`p-4 rounded-md flex items-center ${
              result.success ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
            }`}>
              {result.success ? (
                <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              ) : (
                <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              )}
              <p>{result.message}</p>
            </div>
          )}
        </div>
      </div>

      {/* Endpoint Tester */}
      <ApiEndpointTester />
    </div>
  );
}