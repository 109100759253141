import React, { useState } from 'react';
import { Users, BookOpen, Brain, Upload, Folder, Tag, FileText, HelpCircle, Settings, CreditCard, Cloud, Key, Award, Server } from 'lucide-react';
import { StatsCard } from './components/StatsCard';
import { QuickAction } from './components/QuickAction';
import { UserList } from './components/UserList';
import { ContentManager } from './components/ContentManager';
import { FooterManager } from './components/FooterManager';
import { SeoManager } from './components/SeoManager';
import { SSOManager } from './components/SSOManager';
import { SubscriptionManager } from './components/SubscriptionManager';
import { WebhookTest } from './components/WebhookTest';
import { EdgeFunctionsManager } from './components/EdgeFunctionsManager';
import { PasswordResetTest } from './components/PasswordResetTest';
import { PasswordUpdateTest } from '../../components/admin/PasswordUpdateTest';
import { EntitlementManager } from './components/EntitlementManager';
import { UserEntitlementManager } from './components/UserEntitlementManager';
import { ApiTester } from './components/ApiTester';
import { useStats } from './hooks/useStats';

export default function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('overview');
  const { stats, isLoading: statsLoading } = useStats();

  const renderOverview = () => (
    <div className="grid gap-6 mb-8">
      <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-6">
        <StatsCard
          title="Users"
          value={stats.totalUsers}
          description="Total registered users"
          icon={Users}
          iconColor="text-blue-600"
        />
        <StatsCard
          title="Categories"
          value={stats.totalCategories}
          description="Content categories"
          icon={Folder}
          iconColor="text-purple-600"
        />
        <StatsCard
          title="Topics"
          value={stats.totalTopics}
          description="Learning topics"
          icon={Tag}
          iconColor="text-green-600"
        />
        <StatsCard
          title="Questions"
          value={stats.totalQuestions}
          description="Practice questions"
          icon={HelpCircle}
          iconColor="text-yellow-600"
        />
        <StatsCard
          title="Quizzes"
          value={stats.totalQuizzes}
          description="Active quizzes"
          icon={BookOpen}
          iconColor="text-orange-600"
        />
        <StatsCard
          title="Flashcards"
          value={stats.totalFlashcards}
          description="Study flashcards"
          icon={Brain}
          iconColor="text-indigo-600"
        />
      </div>

      <div className="grid md:grid-cols-2 gap-6">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Quick Actions</h2>
          <div className="space-y-4">
            <QuickAction
              title="Upload Questions"
              description="Add new questions to the question bank"
              icon={Upload}
              iconColor="text-blue-600"
              onClick={() => setActiveTab('content')}
            />
            <QuickAction
              title="Create Flashcards"
              description="Add new flashcards for study"
              icon={Brain}
              iconColor="text-purple-600"
              onClick={() => setActiveTab('content')}
            />
            <QuickAction
              title="Manage Users"
              description="View and manage user accounts"
              icon={Users}
              iconColor="text-green-600"
              onClick={() => setActiveTab('users')}
            />
            <QuickAction
              title="Manage Entitlements"
              description="Create and assign topic entitlements"
              icon={Award}
              iconColor="text-yellow-600"
              onClick={() => setActiveTab('entitlements')}
            />
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">System Status</h2>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Database Status</span>
              <span className="text-green-600">Connected</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Auth Service</span>
              <span className="text-green-600">Active</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Storage Service</span>
              <span className="text-green-600">Available</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Mobile API</span>
              <span className="text-green-600">Online</span>
            </div>
          </div>
        </div>
      </div>

      {/* Password Update Test Component */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <PasswordUpdateTest />
      </div>

      {/* Password Reset Test Component */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <PasswordResetTest />
      </div>

      {/* Webhook Test Component */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <WebhookTest />
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return renderOverview();
      case 'users':
        return <UserList />;
      case 'content':
        return <ContentManager />;
      case 'footer':
        return <FooterManager />;
      case 'seo':
        return <SeoManager />;
      case 'sso':
        return <SSOManager />;
      case 'subscriptions':
        return <SubscriptionManager />;
      case 'edge-functions':
        return <EdgeFunctionsManager />;
      case 'entitlements':
        return <EntitlementManager />;
      case 'user-entitlements':
        return <UserEntitlementManager />;
      case 'api-tester':
        return <ApiTester />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
        </div>

        <div className="mb-8">
          <nav className="flex flex-wrap gap-4">
            <button
              onClick={() => setActiveTab('overview')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'overview'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('users')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'users'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Users
            </button>
            <button
              onClick={() => setActiveTab('content')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'content'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Content
            </button>
            <button
              onClick={() => setActiveTab('entitlements')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'entitlements'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <Award className="h-4 w-4 mr-1" />
                Entitlements
              </div>
            </button>
            <button
              onClick={() => setActiveTab('user-entitlements')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'user-entitlements'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <Key className="h-4 w-4 mr-1" />
                User Entitlements
              </div>
            </button>
            <button
              onClick={() => setActiveTab('api-tester')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'api-tester'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <Server className="h-4 w-4 mr-1" />
                API Tester
              </div>
            </button>
            <button
              onClick={() => setActiveTab('footer')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'footer'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              Footer
            </button>
            <button
              onClick={() => setActiveTab('seo')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'seo'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              SEO
            </button>
            <button
              onClick={() => setActiveTab('sso')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'sso'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <Settings className="h-4 w-4 mr-1" />
                SSO
              </div>
            </button>
            <button
              onClick={() => setActiveTab('subscriptions')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'subscriptions'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <CreditCard className="h-4 w-4 mr-1" />
                Subscriptions
              </div>
            </button>
            <button
              onClick={() => setActiveTab('edge-functions')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'edge-functions'
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              <div className="flex items-center">
                <Cloud className="h-4 w-4 mr-1" />
                Edge Functions
              </div>
            </button>
          </nav>
        </div>

        {renderContent()}
      </div>
    </div>
  );
}