import React, { useState, useEffect } from 'react';
import { 
  Users, 
  Key, 
  Calendar, 
  CheckCircle, 
  XCircle, 
  Search, 
  AlertCircle,
  Trash2
} from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useUsers } from '../hooks/useUsers';

interface UserEntitlement {
  id: string;
  user_id: string;
  entitlement_id: string;
  created_at: string;
  expires_at: string | null;
  is_active: boolean;
  user_name: string;
  entitlement_name: string;
  entitlement_code: string;
  topic_name: string;
  is_ad_free: boolean;
}

export function UserEntitlementManager() {
  const [userEntitlements, setUserEntitlements] = useState<UserEntitlement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { users } = useUsers();

  useEffect(() => {
    fetchUserEntitlements();
  }, []);

  const fetchUserEntitlements = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('user_entitlements')
        .select(`
          *,
          profiles:user_id(full_name),
          entitlements:entitlement_id(
            name,
            code,
            is_ad_free,
            topics:topic_id(name)
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transform the data to a flatter structure
      const transformedData = data.map((item: any) => ({
        id: item.id,
        user_id: item.user_id,
        entitlement_id: item.entitlement_id,
        created_at: item.created_at,
        expires_at: item.expires_at,
        is_active: item.is_active,
        user_name: item.profiles?.full_name || 'Unknown',
        entitlement_name: item.entitlements?.name || 'Unknown',
        entitlement_code: item.entitlements?.code || 'Unknown',
        topic_name: item.entitlements?.topics?.name || 'Unknown',
        is_ad_free: item.entitlements?.is_ad_free || false
      }));

      setUserEntitlements(transformedData);
    } catch (err: any) {
      console.error('Error fetching user entitlements:', err);
      setError(err.message || 'Failed to load user entitlements');
    } finally {
      setLoading(false);
    }
  };

  const handleRevokeEntitlement = async (id: string) => {
    if (!confirm('Are you sure you want to revoke this entitlement?')) return;

    setError(null);
    setSuccess(null);

    try {
      const { error } = await supabase
        .from('user_entitlements')
        .update({ is_active: false })
        .eq('id', id);

      if (error) throw error;
      
      setSuccess('Entitlement revoked successfully');
      fetchUserEntitlements();
    } catch (err: any) {
      console.error('Error revoking entitlement:', err);
      setError(err.message || 'Failed to revoke entitlement');
    }
  };

  const filteredUserEntitlements = userEntitlements.filter(ue => 
    ue.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ue.entitlement_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ue.entitlement_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ue.topic_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading && userEntitlements.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <span className="ml-3">Loading user entitlements...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">User Entitlements</h2>
          <div className="relative">
            <input
              type="text"
              placeholder="Search user entitlements..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Entitlement
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Topic
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ad-Free
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Expires
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUserEntitlements.length === 0 ? (
                <tr>
                  <td colSpan={8} className="px-6 py-4 text-center text-gray-500">
                    No user entitlements found
                  </td>
                </tr>
              ) : (
                filteredUserEntitlements.map((userEntitlement) => (
                  <tr key={userEntitlement.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Users className="h-4 w-4 text-gray-500 mr-2" />
                        <div>
                          <div className="text-sm font-medium text-gray-900">{userEntitlement.user_name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Key className="h-4 w-4 text-gray-500 mr-2" />
                        <div>
                          <div className="text-sm font-medium text-gray-900">{userEntitlement.entitlement_name}</div>
                          <div className="text-sm font-mono text-gray-500">{userEntitlement.entitlement_code}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{userEntitlement.topic_name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {userEntitlement.is_ad_free ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Yes
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          No
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(userEntitlement.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {userEntitlement.expires_at ? (
                        <div className="flex items-center">
                          <Calendar className="h-4 w-4 mr-1" />
                          {new Date(userEntitlement.expires_at).toLocaleDateString()}
                        </div>
                      ) : (
                        <span className="text-gray-500">Never</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {userEntitlement.is_active ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Active
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Revoked
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {userEntitlement.is_active && (
                        <button
                          onClick={() => handleRevokeEntitlement(userEntitlement.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}