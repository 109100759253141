import { supabase } from '../lib/supabase';

export class MobileApiService {
  private static instance: MobileApiService;
  private baseUrl: string;
  private maxRetries = 3;
  private retryDelay = 1000; // 1 second
  private connectionTimeout = 15000; // 15 seconds

  private constructor() {
    // Use the domain URL for API requests
    this.baseUrl = window.location.origin + '/api';
  }

  public static getInstance(): MobileApiService {
    if (!MobileApiService.instance) {
      MobileApiService.instance = new MobileApiService();
    }
    return MobileApiService.instance;
  }

  private async getAuthToken(): Promise<string> {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error || !session) {
      throw new Error('Not authenticated');
    }
    return session.access_token;
  }

  private async retryOperation<T>(operation: () => Promise<T>, context: string): Promise<T> {
    let lastError: any;
    let delay = this.retryDelay;

    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), this.connectionTimeout);

        const result = await Promise.race([
          operation(),
          new Promise<never>((_, reject) => {
            controller.signal.addEventListener('abort', () => {
              reject(new Error('Request timed out'));
            });
          })
        ]);

        clearTimeout(timeoutId);
        return result;
      } catch (error: any) {
        lastError = error;
        console.error(`${context}: Attempt ${attempt} failed:`, error);

        // Check response content type if available
        if (error.response) {
          const contentType = error.response.headers.get('content-type');
          if (contentType && !contentType.includes('application/json')) {
            throw new Error('Invalid API response: Expected JSON but received different content type');
          }
        }

        // Check for specific error types
        if (error.name === 'AbortError') {
          throw new Error('The request timed out. Please check your internet connection and try again.');
        }

        if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
          throw new Error('Unable to connect to the API. Please check your network connection.');
        }

        if (error.status === 401 || error.message.includes('not authenticated')) {
          throw new Error('Your session has expired. Please log in again.');
        }

        if (attempt < this.maxRetries) {
          // Add jitter to prevent thundering herd
          const jitter = Math.random() * 200;
          delay = Math.min(delay * Math.pow(2, attempt - 1) + jitter, 10000);
          console.log(`${context}: Retrying in ${delay}ms`);
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }
      }
    }

    console.error(`${context}: All attempts failed`);
    throw lastError || new Error('The operation could not be completed. Please try again later.');
  }

  async getCurrentUser(): Promise<any> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/users/me`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Invalid API response: Expected JSON but received different content type');
        }
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to get current user (${response.status})`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Invalid API response: Expected JSON but received different content type');
      }

      return await response.json();
    }, 'getCurrentUser');
  }

  async updateUserProfile(updates: any): Promise<any> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/users/me`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updates)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to update profile (${response.status})`);
      }

      return await response.json();
    }, 'updateUserProfile');
  }

  async getUserEntitlements(): Promise<any> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/users/me/entitlements`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to get entitlements (${response.status})`);
      }

      return await response.json();
    }, 'getUserEntitlements');
  }

  async redeemEntitlementCode(code: string): Promise<any> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/entitlements/redeem`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ code })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to redeem code (${response.status})`);
      }

      return await response.json();
    }, 'redeemEntitlementCode');
  }

  async verifyTopicEntitlement(topicId: string): Promise<boolean> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/entitlements/verify?topic_id=${topicId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to verify entitlement (${response.status})`);
      }

      const data = await response.json();
      return data.has_entitlement;
    }, 'verifyTopicEntitlement');
  }

  async verifyAdFreeEntitlement(topicId: string): Promise<boolean> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/entitlements/verify-ad-free?topic_id=${topicId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to verify ad-free entitlement (${response.status})`);
      }

      const data = await response.json();
      return data.is_ad_free;
    }, 'verifyAdFreeEntitlement');
  }

  async getSubscriptionTiers(): Promise<any> {
    return this.retryOperation(async () => {
      const response = await fetch(`${this.baseUrl}/subscriptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to get subscription tiers (${response.status})`);
      }

      return await response.json();
    }, 'getSubscriptionTiers');
  }

  async getUserSubscriptions(): Promise<any> {
    return this.retryOperation(async () => {
      const token = await this.getAuthToken();
      
      const response = await fetch(`${this.baseUrl}/users/me/subscriptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Failed to get subscriptions (${response.status})`);
      }

      return await response.json();
    }, 'getUserSubscriptions');
  }

  async testApiConnection(): Promise<{ success: boolean; message: string }> {
    try {
      const data = await this.getCurrentUser();
      return {
        success: true,
        message: `API connection successful. User: ${data.user.email}`
      };
    } catch (error: any) {
      console.error('API test error:', error);
      return {
        success: false,
        message: error.message || 'Unknown error occurred'
      };
    }
  }
}