import { supabase } from '../lib/supabase';
import { Entitlement, UserEntitlement } from '../types/entitlement';

export class EntitlementService {
  private static instance: EntitlementService;

  private constructor() {}

  public static getInstance(): EntitlementService {
    if (!EntitlementService.instance) {
      EntitlementService.instance = new EntitlementService();
    }
    return EntitlementService.instance;
  }

  async getEntitlements(): Promise<Entitlement[]> {
    const { data, error } = await supabase
      .from('entitlements')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  }

  async getEntitlementsForTopic(topicId: string): Promise<Entitlement[]> {
    const { data, error } = await supabase
      .from('entitlements')
      .select('*')
      .eq('topic_id', topicId)
      .eq('is_active', true)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  }

  async getUserEntitlements(): Promise<UserEntitlement[]> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .from('user_entitlements')
      .select(`
        *,
        entitlement:entitlement_id(*)
      `)
      .eq('user_id', user.id)
      .eq('is_active', true)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  }

  async createEntitlement(entitlement: Partial<Entitlement>): Promise<Entitlement> {
    const { data, error } = await supabase
      .from('entitlements')
      .insert([entitlement])
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async updateEntitlement(id: string, updates: Partial<Entitlement>): Promise<void> {
    const { error } = await supabase
      .from('entitlements')
      .update({
        ...updates,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;
  }

  async deleteEntitlement(id: string): Promise<void> {
    const { error } = await supabase
      .from('entitlements')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }

  async assignEntitlementToUser(userId: string, entitlementId: string, expiresAt?: string): Promise<void> {
    const { error } = await supabase
      .from('user_entitlements')
      .insert([{
        user_id: userId,
        entitlement_id: entitlementId,
        expires_at: expiresAt || null,
        is_active: true
      }]);

    if (error) throw error;
  }

  async revokeUserEntitlement(userEntitlementId: string): Promise<void> {
    const { error } = await supabase
      .from('user_entitlements')
      .update({ is_active: false })
      .eq('id', userEntitlementId);

    if (error) throw error;
  }

  async hasAdFreeEntitlementForTopic(topicId: string): Promise<boolean> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return false;

    const { data, error } = await supabase
      .rpc('has_ad_free_entitlement', {
        p_user_id: user.id,
        p_topic_id: topicId
      });

    if (error) {
      console.error('Error checking ad-free entitlement:', error);
      return false;
    }

    return data || false;
  }

  async redeemEntitlementCode(code: string): Promise<UserEntitlement> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // First, find the entitlement by code
    const { data: entitlement, error: entitlementError } = await supabase
      .from('entitlements')
      .select('*')
      .eq('code', code.toUpperCase())
      .eq('is_active', true)
      .single();

    if (entitlementError) throw new Error('Invalid or expired entitlement code');
    if (!entitlement) throw new Error('Entitlement not found');

    // Check if user already has this entitlement
    const { data: existingEntitlement, error: existingError } = await supabase
      .from('user_entitlements')
      .select('*')
      .eq('user_id', user.id)
      .eq('entitlement_id', entitlement.id)
      .eq('is_active', true)
      .maybeSingle();

    if (existingError) throw existingError;
    if (existingEntitlement) throw new Error('You already have this entitlement');

    // Assign the entitlement to the user
    const { data: userEntitlement, error: assignError } = await supabase
      .from('user_entitlements')
      .insert([{
        user_id: user.id,
        entitlement_id: entitlement.id,
        is_active: true
      }])
      .select(`
        *,
        entitlement:entitlement_id(*)
      `)
      .single();

    if (assignError) throw assignError;
    return userEntitlement;
  }
}