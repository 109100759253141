import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Certifications } from './pages/Certifications';
import { Quiz } from './pages/Quiz';
import { Flashcards } from './pages/Flashcards';
import { FlashcardTopics } from './pages/FlashcardTopics';
import { DemoFlashcards } from './pages/DemoFlashcards';
import { DemoQuiz } from './pages/DemoQuiz';
import { ContentPage } from './pages/ContentPage';
import { Profile } from './pages/Profile';
import { Subscription } from './pages/Subscription';
import { Entitlements } from './pages/Entitlements';
import { ApiTest } from './pages/ApiTest';
import AdminDashboard from './pages/admin/Dashboard';
import { Login } from './pages/auth/Login';
import { Register } from './pages/auth/Register';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { ResetPassword } from './pages/auth/ResetPassword';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AdminRoute } from './components/AdminRoute';

function App() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/demo/flashcards" element={<DemoFlashcards />} />
      <Route path="/demo/quiz" element={<DemoQuiz />} />
      
      {/* Content pages */}
      <Route path="/about" element={<ContentPage />} />
      <Route path="/privacy" element={<ContentPage />} />
      <Route path="/terms" element={<ContentPage />} />
      <Route path="/eula" element={<ContentPage />} />
      <Route path="/refund" element={<ContentPage />} />
      <Route path="/contact" element={<ContentPage />} />
      <Route path="/faqs" element={<ContentPage />} />
      
      {/* Protected routes */}
      <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/entitlements" element={<Entitlements />} />
        <Route path="/api-test" element={<ApiTest />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/quiz/:id" element={<Quiz />} />
        <Route path="/flashcards" element={<FlashcardTopics />} />
        <Route path="/flashcards/:id" element={<Flashcards />} />
        <Route path="/admin" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
      </Route>

      {/* Catch-all redirect */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;