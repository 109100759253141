import { create } from 'zustand';
import { EntitlementService } from '../services/EntitlementService';
import { MobileApiService } from '../services/MobileApiService';
import { UserEntitlement } from '../types/entitlement';

interface EntitlementState {
  userEntitlements: UserEntitlement[];
  isLoading: boolean;
  error: string | null;
  fetchUserEntitlements: () => Promise<void>;
  redeemCode: (code: string) => Promise<void>;
  hasAdFreeEntitlementForTopic: (topicId: string) => Promise<boolean>;
}

export const useEntitlementStore = create<EntitlementState>((set, get) => ({
  userEntitlements: [],
  isLoading: false,
  error: null,
  
  fetchUserEntitlements: async () => {
    try {
      set({ isLoading: true, error: null });
      
      // Try to use the mobile API first
      try {
        const apiService = MobileApiService.getInstance();
        const response = await apiService.getUserEntitlements();
        set({ userEntitlements: response.entitlements, isLoading: false });
        return;
      } catch (apiError) {
        console.warn('Mobile API unavailable, falling back to Supabase:', apiError);
        // Fall back to direct Supabase access
        const entitlementService = EntitlementService.getInstance();
        const userEntitlements = await entitlementService.getUserEntitlements();
        set({ userEntitlements, isLoading: false });
      }
    } catch (error: any) {
      console.error('Error fetching user entitlements:', error);
      set({ error: error.message, isLoading: false });
    }
  },
  
  redeemCode: async (code: string) => {
    try {
      set({ isLoading: true, error: null });
      
      // Try to use the mobile API first
      try {
        const apiService = MobileApiService.getInstance();
        const response = await apiService.redeemEntitlementCode(code);
        
        // Update the entitlements list
        const currentEntitlements = get().userEntitlements;
        set({ 
          userEntitlements: [...currentEntitlements, response.entitlement],
          isLoading: false 
        });
        return;
      } catch (apiError) {
        console.warn('Mobile API unavailable, falling back to Supabase:', apiError);
        // Fall back to direct Supabase access
        const entitlementService = EntitlementService.getInstance();
        const newEntitlement = await entitlementService.redeemEntitlementCode(code);
        
        // Update the entitlements list
        const currentEntitlements = get().userEntitlements;
        set({ 
          userEntitlements: [...currentEntitlements, newEntitlement],
          isLoading: false 
        });
      }
    } catch (error: any) {
      console.error('Error redeeming code:', error);
      set({ error: error.message, isLoading: false });
      throw error;
    }
  },
  
  hasAdFreeEntitlementForTopic: async (topicId: string) => {
    try {
      // Try to use the mobile API first
      try {
        const apiService = MobileApiService.getInstance();
        return await apiService.verifyAdFreeEntitlement(topicId);
      } catch (apiError) {
        console.warn('Mobile API unavailable, falling back to Supabase:', apiError);
        // Fall back to direct Supabase access
        const entitlementService = EntitlementService.getInstance();
        return await entitlementService.hasAdFreeEntitlementForTopic(topicId);
      }
    } catch (error) {
      console.error('Error checking ad-free entitlement:', error);
      return false;
    }
  }
}));