import React, { useState, useEffect } from 'react';
import { 
  Key, 
  Plus, 
  Edit2, 
  Trash2, 
  CheckCircle, 
  XCircle, 
  Search, 
  AlertCircle,
  Users,
  UserPlus,
  X
} from 'lucide-react';
import { EntitlementService } from '../../../services/EntitlementService';
import { Entitlement } from '../../../types/entitlement';
import { useTopics } from '../hooks/useTopics';
import { useUsers } from '../hooks/useUsers';

export function EntitlementManager() {
  const [entitlements, setEntitlements] = useState<Entitlement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedEntitlement, setSelectedEntitlement] = useState<Entitlement | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { topics } = useTopics();
  const { users } = useUsers();
  const entitlementService = EntitlementService.getInstance();

  // Form state for adding/editing entitlements
  const [formData, setFormData] = useState({
    topic_id: '',
    code: '',
    name: '',
    description: '',
    is_ad_free: false,
    is_active: true
  });

  // Form state for assigning entitlements
  const [assignData, setAssignData] = useState({
    user_id: '',
    entitlement_id: '',
    expires_at: ''
  });

  useEffect(() => {
    fetchEntitlements();
  }, []);

  const fetchEntitlements = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await entitlementService.getEntitlements();
      setEntitlements(data);
    } catch (err: any) {
      console.error('Error fetching entitlements:', err);
      setError(err.message || 'Failed to load entitlements');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAssignInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAssignData(prev => ({ ...prev, [name]: value }));
  };

  const generateRandomCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prev => ({ ...prev, code: result }));
  };

  const handleAddEntitlement = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      if (!formData.topic_id || !formData.name || !formData.code) {
        throw new Error('Topic, name, and code are required');
      }

      await entitlementService.createEntitlement(formData);
      setSuccess('Entitlement created successfully');
      setShowAddModal(false);
      setFormData({
        topic_id: '',
        code: '',
        name: '',
        description: '',
        is_ad_free: false,
        is_active: true
      });
      fetchEntitlements();
    } catch (err: any) {
      console.error('Error adding entitlement:', err);
      setError(err.message || 'Failed to create entitlement');
    }
  };

  const handleEditEntitlement = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!selectedEntitlement) return;

    try {
      if (!formData.topic_id || !formData.name || !formData.code) {
        throw new Error('Topic, name, and code are required');
      }

      await entitlementService.updateEntitlement(selectedEntitlement.id, formData);
      setSuccess('Entitlement updated successfully');
      setShowEditModal(false);
      setSelectedEntitlement(null);
      fetchEntitlements();
    } catch (err: any) {
      console.error('Error updating entitlement:', err);
      setError(err.message || 'Failed to update entitlement');
    }
  };

  const handleDeleteEntitlement = async (id: string) => {
    if (!confirm('Are you sure you want to delete this entitlement?')) return;

    setError(null);
    setSuccess(null);

    try {
      await entitlementService.deleteEntitlement(id);
      setSuccess('Entitlement deleted successfully');
      fetchEntitlements();
    } catch (err: any) {
      console.error('Error deleting entitlement:', err);
      setError(err.message || 'Failed to delete entitlement');
    }
  };

  const handleAssignEntitlement = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      if (!assignData.user_id || !assignData.entitlement_id) {
        throw new Error('User and entitlement are required');
      }

      await entitlementService.assignEntitlementToUser(
        assignData.user_id,
        assignData.entitlement_id,
        assignData.expires_at || undefined
      );
      
      setSuccess('Entitlement assigned successfully');
      setShowAssignModal(false);
      setAssignData({
        user_id: '',
        entitlement_id: '',
        expires_at: ''
      });
    } catch (err: any) {
      console.error('Error assigning entitlement:', err);
      setError(err.message || 'Failed to assign entitlement');
    }
  };

  const openEditModal = (entitlement: Entitlement) => {
    setSelectedEntitlement(entitlement);
    setFormData({
      topic_id: entitlement.topic_id,
      code: entitlement.code,
      name: entitlement.name,
      description: entitlement.description || '',
      is_ad_free: entitlement.is_ad_free,
      is_active: entitlement.is_active
    });
    setShowEditModal(true);
  };

  const openAssignModal = (entitlement: Entitlement) => {
    setSelectedEntitlement(entitlement);
    setAssignData({
      user_id: '',
      entitlement_id: entitlement.id,
      expires_at: ''
    });
    setShowAssignModal(true);
  };

  const filteredEntitlements = entitlements.filter(entitlement => 
    entitlement.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    entitlement.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    entitlement.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getTopicName = (topicId: string) => {
    const topic = topics.find(t => t.id === topicId);
    return topic ? topic.name : 'Unknown Topic';
  };

  if (loading && entitlements.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
          <span className="ml-3">Loading entitlements...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">Entitlement Management</h2>
          <div className="flex space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search entitlements..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <button
              onClick={() => {
                setFormData({
                  topic_id: '',
                  code: '',
                  name: '',
                  description: '',
                  is_ad_free: false,
                  is_active: true
                });
                setShowAddModal(true);
              }}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add Entitlement
            </button>
            <button
              onClick={() => setShowAssignModal(true)}
              className="flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              <UserPlus className="h-5 w-5 mr-2" />
              Assign Entitlement
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md flex items-center">
            <CheckCircle className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Code
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Topic
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ad-Free
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredEntitlements.length === 0 ? (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center text-gray-500">
                    No entitlements found
                  </td>
                </tr>
              ) : (
                filteredEntitlements.map((entitlement) => (
                  <tr key={entitlement.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Key className="h-4 w-4 text-gray-500 mr-2" />
                        <span className="font-mono text-sm">{entitlement.code}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{entitlement.name}</div>
                      {entitlement.description && (
                        <div className="text-sm text-gray-500">{entitlement.description}</div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{getTopicName(entitlement.topic_id)}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {entitlement.is_ad_free ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Yes
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                          No
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {entitlement.is_active ? (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Active
                        </span>
                      ) : (
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(entitlement.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => openEditModal(entitlement)}
                        className="text-blue-600 hover:text-blue-900 mr-4"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => openAssignModal(entitlement)}
                        className="text-green-600 hover:text-green-900 mr-4"
                      >
                        <UserPlus className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteEntitlement(entitlement.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Entitlement Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Add New Entitlement</h3>
              <button
                onClick={() => setShowAddModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <form onSubmit={handleAddEntitlement} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Topic
                </label>
                <select
                  name="topic_id"
                  value={formData.topic_id}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Select a topic</option>
                  {topics.map((topic) => (
                    <option key={topic.id} value={topic.id}>
                      {topic.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Code
                </label>
                <div className="flex">
                  <input
                    type="text"
                    name="code"
                    value={formData.code}
                    onChange={handleInputChange}
                    className="flex-1 px-3 py-2 border rounded-l-md"
                    placeholder="Enter code or generate"
                    required
                    maxLength={10}
                  />
                  <button
                    type="button"
                    onClick={generateRandomCode}
                    className="px-3 py-2 bg-gray-200 text-gray-700 rounded-r-md hover:bg-gray-300"
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  placeholder="Entitlement name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  placeholder="Optional description"
                  rows={3}
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_ad_free"
                  name="is_ad_free"
                  checked={formData.is_ad_free}
                  onChange={(e) => setFormData({ ...formData, is_ad_free: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_ad_free" className="ml-2 block text-sm text-gray-900">
                  Ad-Free Access
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="is_active"
                  name="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setShowAddModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Add Entitlement
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Entitlement Modal */}
      {showEditModal && selectedEntitlement && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Edit Entitlement</h3>
              <button
                onClick={() => setShowEditModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <form onSubmit={handleEditEntitlement} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Topic
                </label>
                <select
                  name="topic_id"
                  value={formData.topic_id}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Select a topic</option>
                  {topics.map((topic) => (
                    <option key={topic.id} value={topic.id}>
                      {topic.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Code
                </label>
                <div className="flex">
                  <input
                    type="text"
                    name="code"
                    value={formData.code}
                    onChange={handleInputChange}
                    className="flex-1 px-3 py-2 border rounded-l-md"
                    placeholder="Enter code or generate"
                    required
                    maxLength={10}
                  />
                  <button
                    type="button"
                    onClick={generateRandomCode}
                    className="px-3 py-2 bg-gray-200 text-gray-700 rounded-r-md hover:bg-gray-300"
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  placeholder="Entitlement name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  placeholder="Optional description"
                  rows={3}
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="edit_is_ad_free"
                  name="is_ad_free"
                  checked={formData.is_ad_free}
                  onChange={(e) => setFormData({ ...formData, is_ad_free: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="edit_is_ad_free" className="ml-2 block text-sm text-gray-900">
                  Ad-Free Access
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="edit_is_active"
                  name="is_active"
                  checked={formData.is_active}
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="edit_is_active" className="ml-2 block text-sm text-gray-900">
                  Active
                </label>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setShowEditModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Assign Entitlement Modal */}
      {showAssignModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Assign Entitlement to User</h3>
              <button
                onClick={() => setShowAssignModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            <form onSubmit={handleAssignEntitlement} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  User
                </label>
                <select
                  name="user_id"
                  value={assignData.user_id}
                  onChange={handleAssignInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.full_name} ({user.email})
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Entitlement
                </label>
                <select
                  name="entitlement_id"
                  value={assignData.entitlement_id}
                  onChange={handleAssignInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Select an entitlement</option>
                  {entitlements.filter(e => e.is_active).map((entitlement) => (
                    <option key={entitlement.id} value={entitlement.id}>
                      {entitlement.name} ({entitlement.code}) - {getTopicName(entitlement.topic_id)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Expiration Date (Optional)
                </label>
                <input
                  type="date"
                  name="expires_at"
                  value={assignData.expires_at}
                  onChange={handleAssignInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                  min={new Date().toISOString().split('T')[0]}
                />
                <p className="mt-1 text-sm text-gray-500">
                  Leave blank for no expiration
                </p>
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => setShowAssignModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Assign Entitlement
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}