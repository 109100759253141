import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscriptionStore } from '../store/subscription';
import { useEntitlementStore } from '../store/entitlement';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

export function AdSense() {
  const location = useLocation();
  const advertRef = useRef<HTMLModElement>(null);
  const initialized = useRef(false);
  const { hasSubscription } = useSubscriptionStore();
  const { hasAdFreeEntitlementForTopic } = useEntitlementStore();
  const [isAdFree, setIsAdFree] = React.useState(false);
  
  // Extract topic ID from URL if present
  const topicId = React.useMemo(() => {
    const pathParts = location.pathname.split('/');
    const topicIndex = pathParts.findIndex(part => part === 'flashcards' || part === 'quiz');
    return topicIndex >= 0 && pathParts.length > topicIndex + 1 ? pathParts[topicIndex + 1] : null;
  }, [location.pathname]);

  // Check if user has ad-free entitlement for this topic
  useEffect(() => {
    const checkAdFreeEntitlement = async () => {
      if (topicId) {
        const hasAdFree = await hasAdFreeEntitlementForTopic(topicId);
        setIsAdFree(hasAdFree);
      } else {
        setIsAdFree(false);
      }
    };
    
    checkAdFreeEntitlement();
  }, [topicId, hasAdFreeEntitlementForTopic]);

  useEffect(() => {
    // Don't show ads if user has subscription or ad-free entitlement
    if (hasSubscription || isAdFree) {
      return;
    }

    // Cleanup function to remove the ad when component unmounts
    return () => {
      if (advertRef.current) {
        advertRef.current.innerHTML = '';
        initialized.current = false;
      }
    };
  }, [hasSubscription, isAdFree]);

  useEffect(() => {
    // Don't initialize ads if user has subscription or ad-free entitlement
    if (hasSubscription || isAdFree) {
      return;
    }

    // Reset initialization when location changes
    initialized.current = false;
    
    // Initialize ad
    const initAd = () => {
      try {
        if (
          typeof window !== 'undefined' &&
          window.adsbygoogle &&
          advertRef.current &&
          !initialized.current
        ) {
          // Clear any existing content
          advertRef.current.innerHTML = '';
          
          // Push new ad
          window.adsbygoogle = window.adsbygoogle || [];
          window.adsbygoogle.push({});
          
          // Mark as initialized
          initialized.current = true;
        }
      } catch (err) {
        console.error('AdSense error:', err);
      }
    };

    // Small delay to ensure proper initialization
    const timer = setTimeout(initAd, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [location, hasSubscription, isAdFree]);

  // Don't render anything if user has subscription or ad-free entitlement
  if (hasSubscription || isAdFree) {
    return null;
  }

  return (
    <ins
      ref={advertRef}
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-5405021325408760"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
}